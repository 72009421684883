<template>
  <b-row class="match-height">
    <b-col lg="12">
      <CompanyExplain />
      <Footer />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// import TopView from '@/components/basic/top/TopView.vue'
import CompanyExplain from '@/components/basic/about/CompanyExplain.vue'
import Footer from '@/components/basic/footer/Footer.vue'

export default {
  components: {
    BRow,
    BCol,
    // TopView,
    CompanyExplain,
    Footer,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
</style>
