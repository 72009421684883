<template>
  <div>
    <!-- <b-img
      src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Ftu-tram-pham-UOxMPRPUTOA.jpg?alt=media&token=6a396f2b-85a5-4da0-9dc9-d66c5c548c8e"
      blank-color="#ccc"
      alt="placeholder"
      class="imgStyle_"
    /> -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Ftu-tram-pham-UOxMPRPUTOA.jpg?alt=media&token=6a396f2b-85a5-4da0-9dc9-d66c5c548c8e')`}"
      >
        <b-card-body class="text-center">
          <h2 class="title_ titleSpace_">
            企業情報
          </h2>
        </b-card-body>
      </b-card>
    </section>
    <div class="explainSpace">
      <h2 class="title_">
        <span class="underLine">
          経営理念
        </span>
      </h2>
      <div class="featureSpace">
        <div class="subtitle_">
          MISSON
        </div>
        <b-row>
          <div class="explain_ mt-3 ml-1">
            We create the happiness not only for ourself which support each personʼs potential.
          </div>
          <div class="explain_ mt-1 ml-1">
            一人ひとりの可能性を支援し、繋がっていく幸せな世界をつくります。
          </div>
        </b-row>
      </div>
      <div class="featureSpace">
        <div class="subtitle_">
          VISION
        </div>
        <b-row>
          <div class="explain_ mt-3 ml-1">
            Anytime, anywhere, with anyone.
          </div>
          <div class="explain_ mt-1 ml-1">
            We will contribute to the realization and development of a better society
            by providing services that allow us to learn, work and teach freely and equally.
          </div>
          <div class="explain_ mt-1 ml-1">
            いつでも、どこでも、誰とでも。
          </div>
          <div class="explain_ mt-1 ml-1">
            自由に平等に学び、働き、教えるサービスを提供することで、よりよい社会の実現と発展に貢献します。
          </div>
        </b-row>
      </div>
      <div class="featureSpace">
        <div class="subtitle_">
          STYLE
        </div>
        <b-row>
          <div class="explain_ mt-3 ml-1">
            Harmony 調和
          </div>
          <div class="explain_ mt-1 ml-1">
            信頼の気持ちを持って互いを理解し、多様性を受け入れ、話し合うことで最適解を導きます。
          </div>
        </b-row>
        <b-row>
          <div class="explain_ mt-3 ml-1">
            Respect 尊重
          </div>
          <div class="explain_ mt-1 ml-1">
            自己の尊厳、他者の尊厳に隔てなく敬意を持ち、認め合い、尊敬しあう生き方を最優先します。
          </div>
        </b-row>
      </div>
      <div class="featureSpace">
        <div class="subtitle_">
          <span class="underLine">
            会社概要
          </span>
        </div>
        <b-row>
          <div class="explain_ mb-3 ml-1 mt-3">
            JPCAREERは、2015年に国立建設大学（ベトナム・ハノイ）と提携し、サポート企業と共に優秀な理系ベトナム人材の育成・紹介事業をスタートして以来、多くの実績を上げています。
          </div>
        </b-row>
        <b-row>
          <b-col lg="6">
            <b-row class="mt-3">
              <b-col
                lg="4"
                class="text-right explain_"
              >
                会社名
              </b-col>
              <b-col>
                <div class="explain_">
                  JPCAREER株式会社
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                lg="4"
                class="text-right explain_"
              >
                代表者名
              </b-col>
              <b-col>
                <div class="explain_">
                  代表取締役 田中 宏和
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                lg="4"
                class="text-right explain_"
              >
                所在地
              </b-col>
              <b-col>
                <div class="explain_">
                  〒101-0063<br>
                  東京都千代田区神田淡路町2-1-7
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                lg="4"
                class="text-right explain_"
              >
                連絡先
              </b-col>
              <b-col>
                <div class="explain_">
                  03-5295-7775
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                lg="4"
                class="text-right explain_"
              >
                資本金
              </b-col>
              <b-col>
                <div class="explain_">
                  7,000万円
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.214943779127!2d139.76512081525928!3d35.696327880190815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c1b5a5bc827%3A0x91dba204a84fa1d4!2z44CSMTAxLTAwNjMg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy656We55Sw5reh6Lev55S677yS5LiB55uu77yR4oiS77yX!5e0!3m2!1sja!2sjp!4v1635942400359!5m2!1sja!2sjp"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              class="map_"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    // BImg,
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.imgStyle_ {
    width: 110%;
    height: 320px;
    object-fit: cover;
    margin: -5% -5% 0 -5%;
}
.imgStyle2_ {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.map_ {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.explainSpace {
  width: 70%;
  margin: 5% auto;
}
.title_ {
  font-size: 2rem;
  font-weight: 600;
}
.subtitle_ {
  font-size: 2.5rem;
  font-weight: 600;
  /* margin: 5% 0 0 0; */
}
.explain_ {
  font-size: 1.4rem;
  font-weight: 600;
}
.featureSpace {
  margin: 5% 0 10% 0;
}
.titleSpace_ {
  padding: 2% 5%;
  width: 60%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.6);
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .titleSpace_ {
    padding: 2% 5%;
    width: 20%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.underLine {
  border-bottom: solid 4px rgb(128, 128, 128);
  padding: 0% 0 1% 0;
}
</style>
